import React from "react";
import CustomButton from "../CustomButton";

const themeBackground = {
  gray: "text-gray-2500 bg-gray-2800 border border-gray-2200",
  blue: "bg-blue-1300 text-white border border-blue-1300",
  purple: "bg-purple-1500 text-white border border-purple-1500",
};

const TableHeaderSection = ({ title, tooltipText, plans, icon,iconStyle }) => {
  return (
    <div className="flex flex-col lg:pl-7.5 lg:pr-0 sm:px-5 px-4 ">
      {plans && (
        <div className="justify-end hidden my-10 sm:flex sm:gap-5 lg:gap-0 lg:w-full">
          {plans?.map((plan, index) => (
            <div className="flex flex-col text-center items-center lg:w-full xl:max-w-[234px]  max-w-[200px]">
              <img
                src={plan?.icon?.url}
                w={0}
                h={0}
                alt={plan?.icon?.alt || "img"}
                className="w-7.5 h-auto mb-1.5"
              />
              <div
                className={`mb-5 text-xl font-semibold
              ${
                index === 0
                  ? "text-gray-600"
                  : index === 1
                    ? "text-blue-1300"
                    : "text-purple-1500"
              } font-inter`}
              >
                {plan.heading}
              </div>
              <div className="flex justify-center">
                <CustomButton
                  text={plan?.buttonText}
                  onClick={plan?.onClick}
                  buttonClass={`${themeBackground[plan?.themeColor]} text-sm ${
                    index === 0
                      ? "hover:bg-gray-1600"
                      : index === 1
                        ? "hover:bg-blue-1500"
                        : "hover:bg-purple-1800"
                  }`}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex items-center w-full max-w-lg gap-2 py-5">
        <img
          src={icon?.url}
          alt={icon?.alt || ""}
          className={`h-auto w-11 ${iconStyle}`}

          loading="lazy"
        />
        <div>
          <p className="p-0 m-0 text-2xl font-semibold text-purple-1100">
            {title}
          </p>
          <p className="text-xs m-0 p-0 text-gray-600 font-inter font-normal tracking-[0.3px] ">
            {tooltipText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TableHeaderSection;
